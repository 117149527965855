<template>
    <div class="common-layout bg-white p-3 rounded-3 shadow-sm m-1 mt-2">
        <h5><b>Graduation</b></h5>
        <div class="border-top my-3"></div>
        <div class="container-fluid">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-sm-9">
                        <div class="row">
                            <iframe width="1268" height="713" src="https://www.youtube.com/embed/LurUb1p8Cns?rel=0"
                                title="Digin Dominic | MSc IT Security Graduation | Nottingham Trent University"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: 'MscGraduation'
}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
i {
    font-size: 100px;
}

img {
    width: 60px;
    height: auto;
}
</style>
